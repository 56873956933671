import { configureStore, Action, ThunkDispatch, } from "@reduxjs/toolkit";
import { useDispatch} from "react-redux";
import counterReducer from "./slices/counterSlice";
import chargerReducer from "./slices/chargerSlice";
import userReducer from "./slices/userSlice";
import modalReducer from "./slices/modalSlice";
import listReducer from "./slices/listSlice";

export const store = configureStore({
  reducer: {
    counter: counterReducer,
    charger: chargerReducer, 
    user: userReducer, 
    list: listReducer,
    modal: modalReducer
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
});

export type RootState = ReturnType<typeof store.getState>;

export type ThunkAppDispatch = ThunkDispatch<RootState, void, Action>;

export const useAppThunkDispatch = () => useDispatch<ThunkAppDispatch>();