import {
  IonAvatar,
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonMenu,
  IonMenuToggle,
  IonRow,
  IonText,
} from "@ionic/react";
import React, { useEffect } from "react";
import { RootStateOrAny, useSelector } from "react-redux";
import { authStatus, logout } from "../../store/slices/userSlice";
import {
  batteryChargingOutline,
  carSportOutline,
  cashOutline,
  exitOutline,
  helpBuoy,
  layers,
  personCircleOutline,
  shieldCheckmark,
  walletOutline,
} from "ionicons/icons";

import { useAppThunkDispatch } from "../../store/store";
import { useHistory } from "react-router";
import { useLocation } from "react-router-dom";
import {version} from "../../../src/autobuild_version";

interface AppPage {
  url: string;
  iosIcon: string;
  mdIcon: string;
  title: string;
}

const appPages: AppPage[] = [
  {
    title: "Account",
    url: "/profile",
    iosIcon: personCircleOutline,
    mdIcon: personCircleOutline,
  },
  {
    title: "Charging History",
    url: "/sessions",
    iosIcon: batteryChargingOutline,
    mdIcon: batteryChargingOutline,
  },
  {
    title: "Transaction History",
    url: "/transactions",
    iosIcon: cashOutline,
    mdIcon: cashOutline,
  },
  {
    title: "My Vehicles",
    url: "/vehicles",
    iosIcon: carSportOutline,
    mdIcon: carSportOutline,
  },
  {
    title: "Socket Credits",
    url: "/wallet",
    iosIcon: walletOutline,
    mdIcon: walletOutline,
  },
  {
    title: "Help & Support",
    url: "/help",
    iosIcon: helpBuoy,
    mdIcon: helpBuoy,
  },
  {
    title: "Privacy",
    url: "/privacy",
    iosIcon: shieldCheckmark,
    mdIcon: shieldCheckmark,
  },
];

const Menu: React.FC = () => {
  const dispatch = useAppThunkDispatch();
  const location = useLocation();
  const Router = useHistory();

  let firstName = useSelector((state: RootStateOrAny) => state.user.firstName);
  let status = useSelector(authStatus);

  const onLogout = async () => {
    localStorage.removeItem("token");
    localStorage.removeItem("phone");
    localStorage.removeItem("otp");
    dispatch(logout());
  };

  useEffect(()=> {
    if(!status) {
      Router.push("/login");
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status])

  return (
    <IonMenu contentId="main" type="overlay" swipeGesture={false}>
      <IonContent className="flex space-evenly">
        <IonGrid>
          <IonRow className="items-center" >
            <IonCol size="3">
              <IonAvatar 
              className="w-8 h-8"
              >
                <img src="/assets/default_profile.png" className="object-fit" alt="profilepic"/>
              </IonAvatar>
            </IonCol>
            <IonCol className="flex-grow align-items-center">
              <IonText className="text-lg font-semibold">Hello
              <span className="text-primary pl-1">{firstName}!</span>
              </IonText>
            </IonCol>
          </IonRow>
        </IonGrid>
        <IonList className="mt-6">
          {appPages.map((appPage, index) => {
            return (
              <IonMenuToggle key={index}>
                <IonItem
                  className={
                    location.pathname === appPage.url ? "selected" : ""
                  }
                  routerLink={appPage.url}
                  routerDirection="none"
                  lines="none"
                  detail={false}
                >
                  <IonIcon slot="start" icon={appPage.iosIcon} />
                  <IonLabel>{appPage.title}</IonLabel>
                </IonItem>
              </IonMenuToggle>
            );
          })}
        </IonList>

        <IonMenuToggle>
          <div className="w-full absolute bottom-3 text-center">
            <div>Version {version}</div>
            <div>
                <IonButton
                fill="clear"
                className=""
                onClick={onLogout}
              >
                <IonIcon slot="start" icon={exitOutline} />
                  <span>Logout</span>                
              </IonButton>
            </div>
          </div>

        </IonMenuToggle>
      </IonContent>
    </IonMenu>
  );
};

export default Menu;
