import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { apiSecure } from '../../utils/util';

export const getSessions = createAsyncThunk(
  "list/sessions/getSessions",
  async (userTag: string) => {
    try {
      const response = apiSecure.get(`/sessions/${userTag}`)
      return await response
    } catch (error) {
      return error
    }
  }
);

export const getTransactions = createAsyncThunk(
  "list/transactions/getTransactions",
  async (userTag: string) => {
    try {
      const response = apiSecure.get(`/wallet/${userTag}/history`)
      return await response
    } catch (error) {
      return error
    }
  }
);

export const listInitialState = {
  loading: false, 
  hasErrors: false, 
  allSessions: [],
  selectedSession: [],
  allTransactions: [],
  error: {}
}

export const listSlice = createSlice({
  name: "list",
  initialState: listInitialState,
  reducers: {
    getSessionDetail: (state, action) => {
      let tranId = Number(action.payload);
      let allSessions = JSON.parse(JSON.stringify(state.allSessions))
      let selectedSession = allSessions.filter((obj:any) => obj.id === tranId)
      state.selectedSession = selectedSession;
  },
  },
  extraReducers: {
    [getSessions.pending.type]: (state: any, action) => {
      state.loading = true;
    }, 
    [getSessions.fulfilled.type]: (state, action) => {
      let getChargeStationInfoResponse = action.payload;
      let infoResponse = getChargeStationInfoResponse.data.desc;
      let infoCount = getChargeStationInfoResponse.data.count;
      let sessions = getChargeStationInfoResponse.data.trans;
      if (infoCount > 0){
        state.allSessions = sessions;
      } else {
          state.allSessions = []
      }
      state.loading = false;
    },
    [getSessions.rejected.type]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    }, 
    [getTransactions.pending.type]: (state: any, action) => {
      state.loading = true;
    }, 
    [getTransactions.fulfilled.type]: (state, action) => {
      state.loading = false;
      let getChargeStationInfoResponse = action.payload;
      let transactions = getChargeStationInfoResponse.data.transactions
        state.allTransactions = transactions;
    },
    [getTransactions.rejected.type]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    }
  }
});

export const {getSessionDetail} = listSlice.actions;

export const sessionSummary = (state: any) => state.list.selectedSession;

export const isListDataLoading = (state:any) => state.list.loading;


export default listSlice.reducer;
