import { createSlice } from "@reduxjs/toolkit";

export const modalSlice = createSlice({
  name: "modal",
  initialState: {
    paymentModal: false,
    notificationModal: false, 
    favouritesModal: false, 
    vehicleModal: false, 
    updateModal: false,
    walletCreditDescModal: false,
    walletSocketPeCreditDescModal: false,
    confirmChargePointDetailsModal: false,
    estimateSessionCostModal: false,    
    estimateSocketPeCreditDescModal: false,
    profileSocketPeCreditDescModal: false,
    selectionChooseTimeDescModal: false,
  },
  reducers: {
    togglePaymentModal: (state, action) => {
      state.paymentModal = action.payload;
    },
    toggleNotificationModal: (state, action) => {
        state.notificationModal = action.payload;
    }, 
    toggleFavouritesModal: (state, action) => {
        state.favouritesModal = action.payload;
    }, 
    toggleVehicleModal: (state, action) => {
        state.vehicleModal = action.payload;
    }, 
    toggleWalletCreditDescModal: (state, action) => {
      state.walletCreditDescModal = action.payload;
    }, 
    toggleWalletSocketPeCreditDescModal: (state, action) => {
      state.walletSocketPeCreditDescModal = action.payload;
    }, 
    toggleEstimateSocketPeCreditDescModal: (state, action) => {
      state.estimateSocketPeCreditDescModal = action.payload;
    }, 
    toggleProfileSocketPeCreditDescModal: (state, action) => {
      state.profileSocketPeCreditDescModal = action.payload;
    }, 
    
    toggleSelectionChooseTimeDescModal: (state, action) => {
      state.selectionChooseTimeDescModal = action.payload;
    }, 
    toggleConfirmChargePointDetailsModal: (state, action) => {
      state.confirmChargePointDetailsModal = action.payload;
    }, 
    toggleEstimateSessionCostModal: (state, action) => {
      state.estimateSessionCostModal = action.payload;
    }, 
    toggleUpdateModal: (state, action) => {
      state.updateModal = action.payload;
  }
  },
});

export const { 
  togglePaymentModal, 
  toggleNotificationModal, 
  toggleFavouritesModal, 
  toggleVehicleModal, 
  toggleWalletCreditDescModal,
  toggleWalletSocketPeCreditDescModal,
  toggleEstimateSocketPeCreditDescModal,
  toggleProfileSocketPeCreditDescModal,  
  toggleSelectionChooseTimeDescModal,
  toggleConfirmChargePointDetailsModal, 
  toggleEstimateSessionCostModal,
  toggleUpdateModal 
} = modalSlice.actions;

export const updateModalStatus = (state: any) => state.modal.updateModal;
export const walletSocketPeCreditDescModalStatus = (state: any) => state.modal.walletSocketPeCreditDescModal;

export default modalSlice.reducer;
