import { api, apiSecure } from "../../utils/util";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

interface chargeWallet {
  idTag: string;
  amount: number;
  isInflow: Boolean;
  currency: string;
  status: string;
}

interface regDataObj {
  firstName: string;
  lastName: string;
  phone: string;
  otp: string;
}

interface loginDataObj {
  userId: string;
  code: string;
  token: string;
}

interface phoneCreds {
  otp: string;
  phoneNumber: string;
}

interface updateObj {
  idTag: string;
  firstName: string;
  lastName: string;
  email: string;
}

interface geoSearch {
  idTag: string,
  lat: Number;
  lng: Number;
  radius: Number;
}

export const fetchWalletBalance = createAsyncThunk(
  "user/userWalletBalance",
  (userId: string) =>
    apiSecure
      .get(`wallet/${userId}/balance`)
      .then((response) => response.data)
      .catch((error) => error)
);

// /get-chargers-in-radius/idTag/:idTag/lat/:lat/long/:long/radiusInMiles/:radius

export const fetchUserStationsForGeo = createAsyncThunk(
  "user/stationsForGeo",
  async (geoParams: geoSearch) => {
    const {idTag, lat, lng, radius} = geoParams;
    try {
      const response = apiSecure
      .get(`get-chargers-in-radius/idTag/${idTag}/lat/${lat}/long/${lng}/radiusInMiles/${radius}`);
      const stationData = await response;      
      return { data: stationData['data'], error: null };
    } catch(err: any) {
      const errData = err['data'];
      return { data: null, error: errData };
      // return err;
    }
    
  }
);

export const chargeWallet = createAsyncThunk(
  "user/transaction",
  async (chargeDetails: chargeWallet) => {
    const { idTag, amount, isInflow, currency, status } = chargeDetails;
    try {
      const response = apiSecure.post(`/wallet`, {
        idTag: idTag,
        amount: amount,
        isInflow: isInflow,
        currency: "INR",
        status: status,
      });
      return await response;
    } catch (error) {
      return error;
    }
  }
);

export const signUp = createAsyncThunk(
  "user/signup",
  async (registrationCreds: regDataObj, thunkApi) => {
    const { firstName, lastName, phone, otp } = registrationCreds;
    try {
      return await api
        .post(`/user_registration`, {
          firstName: firstName,
          lastName: lastName,
          phone: phone,
          otp: otp,
        })
        .then((response) => {
          let id = response.data.userId;
          let loginDataObj = {
            userId: id,
            code: otp,
            token: ""
          };
      
          return thunkApi.dispatch(login(loginDataObj));
        });
      //
    } catch (error) {
      return error;
    }
  }
);

export const login = createAsyncThunk("user/login", async (loginObj: loginDataObj) => {
  let {userId, code, token} = loginObj;
  let apiUrl = "/user_login?";
  if(token) {
    // apiUrl = apiUrl + "token="+token+"&key=AIzaSyCduXWHSNnunslAsDE-dU48y9j6KzDqUHc";
    apiUrl = apiUrl + "token="+token+"";
  } else {
    // apiUrl = apiUrl + "id="+userId+"&code="+code+"&key=AIzaSyCduXWHSNnunslAsDE-dU48y9j6KzDqUHc";
    apiUrl = apiUrl + "id="+userId+"&code="+code+"";
  }
  // `/user_login?id=${userId}`
  try {
    return await api.get(apiUrl).then((response) => {
      let user = response.data;
      return { ...user, isNew: false, isError: false };
    });
  } catch (error: any) {
    let statusCode = error.response.status;
    if (statusCode === 404) {
      // user not found
      return {
        isNew: true,
        userId: userId,
        isError: false
      };
    } else if (statusCode === 400 ){
      // bad request 
      return {
        isNew: false,
        userId: "",
        isError: true
      };
    }
  }
});

export const loadUser = createAsyncThunk(
  "user/load",
  async (userID: string) => {
    try {
      return await apiSecure.get(`/user/${userID}`).then((response) => {
        let user = response.data;
        return user;
      });
    } catch (error: any) {
      let statusCode = error.response.status;
      if (statusCode === 400) {
        return {
          isNew: true,
          userId: userID,
        };
      }
    }
  }
);

export const updateUser = createAsyncThunk(
  "user/update",
  async (updates: updateObj) => {
    let { idTag, firstName, lastName, email } = updates;
    try {
      return await apiSecure
        .patch(`/user/`, {
          idTag: idTag,
          firstName: firstName,
          lastName: lastName,
          email: email,
        })
        .then((response) => {
          let user = response.data;
          return user;
        });
    } catch (error: any) {
      console.log("Error at update", error);
    }
  }
);

//OTP Verification

/* 
  Step 1 - Send OTP to Phone

  Step 2 - Verify Client Input
  */

export const sendOtp = createAsyncThunk(
  "user/Otp/send",
  async (phone: string) => {
    try {
      return await api
        .post(`/send-tw-verification`, {
          phoneNumber: "+91" + phone,
        })
        .then((response) => response);
    } catch (error: any) {
      return error;
    }
  }
);

export const verifyOtp = createAsyncThunk(
  "user/Otp/verify",
  async (phoneCreds: phoneCreds, thunkApi) => {
    let { phoneNumber, otp } = phoneCreds;
    try {
      return await api
        .post(`/verify-tw-otp`, {
          phoneNumber: "+91" + phoneNumber,
          otp: otp,
        })
        .then((response: any) => {
          let data = response.data;

          if (data.status === "approved") {
            let phoneNumber = data.to;
            return {
              status: data.status,
              phoneNumber: phoneNumber.slice(3),
            };
          }

          if (data.status === "pending") {
            return {
              status: data.status,
              phoneNumber: "",
            };
          }
        })
        .then((response: any) => {
          let phoneNumber = response.phoneNumber;
          let status = response.status;
          let loginDataObj = {
            userId: phoneNumber,
            code: otp,
            token: ""
          };
          
          if (phoneNumber.length) {
            return thunkApi.dispatch(login(loginDataObj));
          } else {
            return status;
          }
        });
    } catch (error: any) {
      let statusCode = error.response.status;
      if (statusCode === 500) {
        return false;
      }
    }
  }
);

export const userInitialState =  () => ({
  isAuthenticated: false,
  isNew: true,
  isCharging: false,
  rfidCharging: false,
  otpStatus: false,
  loading: false,
  hasErrors: false,
  firstName: "",
  lastName: "",
  email: "",
  phone: "",
  userId: "",
  loginToken: "",
  idTag: "",
  rfid: "",
  vehicle: {
    primary: "",
  },
  wallet: {
    balance: 0,
    due: 0,
  },
  selection: {
    amount: 0,
    time: "",
    timeInSecs: 0
  }, 
  session: {
    startTime: "",
    endTime: "",
    connectorId: "",
    clientId: "",
    chargeBoxIdentity: ""
  },
  defaultLocation: { lat: 17.741312, lng: 83.309835 },
  currentLocation: { lat: 17.741312, lng: 83.309835 },
  stationsForGeo: [],
  error: {},
});

/* 
idTag: "077d25bc836",
username: "",
    email: "",
    isFetching: false,
    isSuccess: false,
    isError: false,
    errorMessage: "",
*/

export const userSlice = createSlice({
  name: "user",
  initialState: userInitialState,
  reducers: {
    connectorSelection: (state, action) => {
      state.session.connectorId = action.payload;
    },
    updateStationInfo: (state, action) => {
      let stationInfo = action.payload
      state.session.clientId = stationInfo.chargerId;
      state.session.chargeBoxIdentity = stationInfo.chargerBoxId;
    },
    incrementByAmount: (state, action) => {
      state.wallet.balance += action.payload;
    },
    updateVerifiedPhone: (state, action) => {
      state.userId = action.payload;
    },
    selectionByAmount: (state, action) => {
      state.selection.amount = action.payload;
      state.selection.time = "";
    },
    selectionByTime: (state, action) => {
      let timeSecs = Number(action.payload) * 60;
      state.selection.time = action.payload;
      state.selection.timeInSecs = timeSecs;
      state.selection.amount = 0;
    },
    sessionStart: (state, action) => {
      state.isCharging = action.payload;
      state.session.startTime = "";
    },
    sessionEnd: (state, action) => {
      state.isCharging = action.payload;
      state.session.startTime = "";
      state.session.endTime = "";
    },
    logout:state => userInitialState(),
    errorHandler: (state, action) => {
      state.error = action.payload;
    },
  },
  extraReducers: {
    [fetchWalletBalance.pending.type]: (state: any, action) => {
      state.loading = true;
    },
    [fetchWalletBalance.fulfilled.type]: (state, action) => {
      let getWalletResponse = action.payload;
      if (getWalletResponse.wallet != null) {
        state.wallet.balance = getWalletResponse.wallet.balance;
      } else {
        state.wallet.balance = 0;
      }
    },
    [fetchWalletBalance.rejected.type]: (state, action) => {
      state.error = action.payload;
    },
    [chargeWallet.pending.type]: (state: any, action) => {
      state.loading = true;
    },
    [chargeWallet.fulfilled.type]: (state, action) => {
      let getChargeStationStartResponse = action.payload;
      let chargeResponse = getChargeStationStartResponse.data;
      //console.log("Start Response in Redux", getChargeStationStartResponse)
      if (chargeResponse.desc === "New balance") {
        state.wallet.balance = chargeResponse.balance;
      }
    },
    [chargeWallet.rejected.type]: (state, action) => {
      state.error = action.payload;
    },
    [sendOtp.pending.type]: (state: any, action) => {
      state.loading = true;
    },
    [sendOtp.fulfilled.type]: (state, action) => {
      state.loading = false;
    },
    [sendOtp.rejected.type]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    [verifyOtp.pending.type]: (state: any, action) => {
      state.loading = true;
    },
    [verifyOtp.fulfilled.type]: (state, action) => {
      let isOTPVerified = action.payload;
      state.loading = false;
      state.otpStatus = isOTPVerified;
    },
    [verifyOtp.rejected.type]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    [signUp.pending.type]: (state: any, action) => {
      state.loading = true;
    },
    [signUp.fulfilled.type]: (state, action) => {
      let signupResponse = action.payload;
      //let signUpData = signupResponse.payload
      state.loading = false;
    },
    [signUp.rejected.type]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    [fetchUserStationsForGeo.pending.type]: (state: any, action) => {
      state.loading = true;
    },
    [fetchUserStationsForGeo.fulfilled.type]: (state, action) => {
      let fetchUserStationsForGeoResponse = action.payload;
      if (fetchUserStationsForGeoResponse && fetchUserStationsForGeoResponse['data'] && 
      fetchUserStationsForGeoResponse['data']['stations']){
        state.stationsForGeo = fetchUserStationsForGeoResponse['data']['stations'];
      } else {
        state.stationsForGeo = [];  
      }
      state.loading = false;
    },
    [fetchUserStationsForGeo.rejected.type]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    [login.pending.type]: (state: any, action) => {
      state.loading = true;
    },
    [login.fulfilled.type]: (state, action) => {
      let loginResponse = action.payload;
      let resSize = Object.keys(loginResponse).length;
      if (resSize > 2) {
        state.isNew = false;
        state.loading = false;
        state.idTag = loginResponse.idTag;
        state.firstName = loginResponse.firstName;
        state.lastName = loginResponse.lastName;
        state.email = loginResponse.email;
        state.phone = loginResponse.phone;
        state.rfid = loginResponse.rfid;
        state.userId = loginResponse.userId;
        state.loginToken = loginResponse.token;
        localStorage.setItem("token", loginResponse.token);              
        state.otpStatus = true;
        state.isAuthenticated = true;
      } else {
        state.isNew = true;
        state.loading = false;
        state.otpStatus = false;
        state.userId = loginResponse.userId;
        state.loginToken = "";
      }
    },
    [login.rejected.type]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    [loadUser.pending.type]: (state: any, action) => {
      state.loading = true;
    },
    [loadUser.fulfilled.type]: (state, action) => {
      let getUserData = action.payload;
      if (getUserData && getUserData.desc === "User found") {
        let userData = getUserData.user;
        state.wallet.balance = userData.walletBalance;
        state.otpStatus = true;
        state.idTag = userData.idTag;
        state.isCharging = userData.isCharging;

        if (userData.isCharging && userData.chargingIdTag !== '') {
          state.idTag = userData.chargingIdTag;
        }

        let stationId = userData.stationEndpoint;
        let stationIdRaw = '';
        let encodedChargerClientId = '';
        if (stationId) {
          if (stationId.endsWith('/')) {
            stationIdRaw = stationId.substring(0, stationId.length - 1);
          }
          if (stationId.startsWith('/')) {
            stationIdRaw = stationId.substring(1, stationId.length);
          }
          // let stationIdRaw = stationId.substring(1)
          encodedChargerClientId = encodeURIComponent(stationIdRaw)
          } 

        state.session.clientId = encodedChargerClientId;
        state.session.startTime = userData.chargeStartTime;
        state.session.endTime = userData.chargeStopTime;
        state.session.chargeBoxIdentity = userData.chargeBoxIdentity; //chargeBoxIdentity
        state.session.connectorId = userData.chargingConnector;
        state.loading = false;
      } else {
        state.wallet.balance = 0;
        state.loading = false;
      }
    },
    [loadUser.rejected.type]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    [updateUser.pending.type]: (state: any, action) => {
      state.loading = true;
    },
    [updateUser.fulfilled.type]: (state, action) => {
      let updated = action.payload;
      state.firstName = updated.firstName;
      state.lastName = updated.lastName;
      state.email = updated.email;
      state.loading = false;
    },
    [updateUser.rejected.type]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
  },
});

export const {
  connectorSelection,
  incrementByAmount,
  updateVerifiedPhone,
  selectionByAmount,
  selectionByTime,
  sessionStart,
  sessionEnd,
  logout,
  errorHandler, 
  updateStationInfo
} = userSlice.actions;

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched
/* export const incrementAsync = (amount: number) => (dispatch: any) => {
  setTimeout(() => {
    dispatch(incrementByAmount(amount));
  }, 1000);
}; */

//Auth 
export const authStatus = (state: any) => state.user.isAuthenticated;
export const otpStatus = (state: any) => state.user.otpStatus;

//Home
export const loginId = (state: any) => state.user.userId;
export const userCharging = (state: any) => state.user.isCharging;
export const userLoginToken = (state: any) => state.user.loginToken;
export const userRfidCharging = (state: any) => state.user.rfidCharging;

//Charger Selection 
export const selectedConnector = (state:any) => state.user.session.connectorId;
export const selectedTime = (state: any) => state.user.selection.timeInSecs;

//Loaded User
export const idTag = (state: any) => state.user.idTag;
export const firstName = (state: any) => state.user.firstName;
export const lastName = (state: any) => state.user.lastName;
export const email = (state: any) => state.user.email;
export const phone = (state: any) => state.user.phone;
export const showBalance = (state: any) => state.user.wallet.balance;

// Active Charge Session Indicators
export const userChargerClientId = (state: any) => state.user.session.clientId;
export const userChargerBoxId = (state: any) => state.user.session.chargeBoxIdentity;
export const userConnecterId = (state: any) => state.user.session.connectorId;
export const duration = (state: any) => state.user.selection.timeInSecs;
export const startTime = (state: any) => state.user.session.startTime;
export const endTime = (state: any) => state.user.session.endTime;

// Location 
export const currentLocation = (state: any) => state.user.session.currentLocation;
export const stationsForGeo = (state:any) => state.user.session.stationsForGeo;

export default userSlice.reducer;
