import { useEffect, useState } from "react";
import { RouteProps } from "react-router";
import { Redirect, Route } from "react-router-dom";

interface ProtectedRouteProps extends RouteProps {
  component: React.ComponentType<any>;
  path: string;
  isAuthenticated: boolean;
}

export const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
  component: Component,
  path,
  isAuthenticated,
}) => {
  useEffect(() => {
  }, []);
  return (
    <Route
      path={path}
      render={() => (isAuthenticated ? <Component /> : <Redirect to="/" />)}
    />
  );
};
