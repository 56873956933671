const Loader = () => {
    return (
      <div
        className="bg-transparent"
      >
       <img src="/assets/gif/loading.gif" alt="loading..." className="w-20 h-20 bg-transparent fixed z-20 m-auto inset-x-0 inset-y-0" />
      </div>
    );
  };
  
  export default Loader;
  