import { Capacitor, Plugins } from "@capacitor/core";
import { IonApp, IonRouterOutlet } from "@ionic/react";
import React, { Suspense, useEffect, useState } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { RootStateOrAny, useSelector } from "react-redux";
import { toggleEstimateSessionCostModal, toggleEstimateSocketPeCreditDescModal, togglePaymentModal, toggleProfileSocketPeCreditDescModal, toggleSelectionChooseTimeDescModal, toggleUpdateModal, toggleWalletCreditDescModal, toggleWalletSocketPeCreditDescModal } from "./store/slices/modalSlice";
import { useHistory, useLocation } from 'react-router-dom';

import {App} from "@capacitor/app";
import { IonReactRouter } from "@ionic/react-router";
import Loader from "./components/loading/Loader";
import Menu from "./components/nav/Menu";
import { ProtectedRoute } from "./utils/ProtectedRoute";
import animationBuilder from "./utils/AnimationBuilder";
import { authStatus } from "./store/slices/userSlice";
import { useAppThunkDispatch } from "./store/store";

/* import TabRootPage from "./components/tabs/TabRoot" */

// Dynamic Imports
const Welcome = React.lazy(() => import("./pages/Welcome"));
const Login = React.lazy(() => import("./pages/Login"));
const SignUp = React.lazy(() => import("./pages/SignUp"));
const Home = React.lazy(() => import("./pages/Home"));
const Notifications = React.lazy(() => import("./pages/Notifications"));
const Scanner = React.lazy(() => import("./pages/Scanner"));
const Confirm = React.lazy(() => import("./pages/Confirmation"));
const Select = React.lazy(() => import("./pages/Selection"));
const Estimate = React.lazy(() => import("./pages/Estimate"));
const Charging = React.lazy(() => import("./pages/Charging"));
const Bill = React.lazy(() => import("./pages/Bill"));
const Account = React.lazy(() => import("./pages/Account"));
const Vehicles = React.lazy(() => import("./pages/Vehicles"));
const Wallet = React.lazy(() => import("./pages/Wallet"));
const Transactions = React.lazy(() => import("./pages/Transactions"));
const Sessions = React.lazy(() => import("./pages/Sessions"));
const Sessiondetail = React.lazy(() => import("./pages/Sessiondetail"));
const Help = React.lazy(() => import("./pages/Help"));
const PrivacyPolicy = React.lazy(() => import("./pages/PrivacyPolicy"));
const Location = React.lazy(() => import("./pages/Location"));
const OTP = React.lazy(() => import("./pages/Otp"));

//Blog
const Blog = React.lazy(() => import("./pages/Blog"));

//Auth Info


const MainApp = () => {
  const dispatch = useAppThunkDispatch();
  const isAuthenticated = useSelector(authStatus)
  const [validAuth, setValidAuth] = useState<boolean>(isAuthenticated);
  // const history = useHistory();
  // let location;
  // const location = useLocation();

  useEffect(() => {
      setValidAuth(!isAuthenticated)
  }, [validAuth]);


  useEffect(() => {

    /*App.addListener('backButton',  ({ canGoBack }) => {
      console.log('Back button handler... canGoBack is', canGoBack);
      if(!canGoBack || 
        window.location.pathname === "/" || 
        window.location.pathname === "/home" || 
        window.location.pathname === "/login") {
        let ans = window.confirm("Are you sure to exit?");  
        if (ans) {
          App.exitApp();
        }
      } else {
        window.history.back();
        // history.goBack();
      }
    });*/

    window.addEventListener('load', function() {
      window.history.pushState({noBackExitsApp: true}, '')
      if (window.location.pathname === "/login") {
        // do not load this path;
        // window.history.pushState({noBackExitsApp: true}, '')
      } else {
        //window.history.pushState({noBackExitsApp: true}, '')
      }

    });

    window.addEventListener('popstate', async function(e) {

      if (window.location.pathname === "/home") {
        // if home, then pushstate as no back
        window.history.pushState({noBackExitsApp: true}, '')        
      } else if(e.state && e.state.noBackExitsApp) {
        window.history.pushState({noBackExitsApp: true}, '')        
      } else {
        // do nothing 
      }
    });    
    
  }, []);


  return (
    <IonApp>
      <IonReactRouter>
        <Menu />
        <IonRouterOutlet id="main" animation={animationBuilder}>
          <Switch>
            <Suspense fallback={<Loader />}>
              <Route path="/" exact>
                {validAuth ? (
                  <Redirect to="/home" />
                ) : (
                  <Redirect to="/login" />
                )}
              </Route>
              <Route path="/login" component={Login} />
              <Route path="/otp" component={OTP} />
              <Route path="/signup" component={SignUp} />
              <Route path="/privacy" component={PrivacyPolicy} />
              <ProtectedRoute isAuthenticated={validAuth} path="/home" component={Home} />
              <ProtectedRoute isAuthenticated={validAuth} path="/notify" component={Notifications} />
              <ProtectedRoute isAuthenticated={validAuth} path="/maps" component={Location} />
              <ProtectedRoute isAuthenticated={validAuth} path="/profile" component={Account} />
              <ProtectedRoute isAuthenticated={validAuth} path="/vehicles" component={Vehicles} />
              <ProtectedRoute isAuthenticated={validAuth} path="/wallet" component={Wallet} />
              <ProtectedRoute isAuthenticated={validAuth} path="/scan" component={Scanner} />
              <ProtectedRoute isAuthenticated={validAuth} path="/confirm" component={Confirm} />
              <ProtectedRoute isAuthenticated={validAuth} path="/select" component={Select} />
              <ProtectedRoute isAuthenticated={validAuth} path="/estimate" component={Estimate} />
              <ProtectedRoute isAuthenticated={validAuth} path="/charging" component={Charging} />
              <ProtectedRoute isAuthenticated={validAuth} path="/bill" component={Bill} />
              <ProtectedRoute isAuthenticated={validAuth} path="/transactions" component={Transactions} />
              <ProtectedRoute isAuthenticated={validAuth} path="/sessions" component={Sessions} />
              <ProtectedRoute isAuthenticated={validAuth} path="/sessions/:id" component={Sessiondetail} />
              <ProtectedRoute isAuthenticated={validAuth} path="/help" component={Help} />
              <ProtectedRoute isAuthenticated={validAuth} path="/blog/1" component={Blog} />
              <ProtectedRoute isAuthenticated={validAuth} path="/blog/2" component={Blog} />
            </Suspense>
          </Switch>
        </IonRouterOutlet>
      </IonReactRouter>
    </IonApp>
  );
};

export default MainApp;
