import axios from 'axios';
// PROD
const key_prod= "AIzaSyCDu2o1w9rG7SJozgaS0RBOHk9iZUKj4jM";
const api_url = "https://restapigateway26eleven-kebywspzpq-el.a.run.app";
// const key_prod= "AIzaSyCduXWHSNnunslAsDE-dU48y9j6KzDqUHc";
// const api_url = "https://restapigateway26eleven-ky5g56nkqq-el.a.run.app";

// DEV
const key_dev = "AIzaSyCduXWHSNnunslAsDE-dU48y9j6KzDqUHc";
const dev_url = "https://restapigateway26eleven-ky5g56nkqq-el.a.run.app";
// const key_dev= "AIzaSyCDu2o1w9rG7SJozgaS0RBOHk9iZUKj4jM";
// const dev_url = "https://restapigateway26eleven-kebywspzpq-el.a.run.app";

const __DEV__ = document.domain === "localhost";

export const api = axios.create({
    baseURL: __DEV__ ? dev_url: api_url,
    headers: {
        "Content-Type": "application/json",
    },
});

export const baseUrl = __DEV__ ? dev_url: api_url;

export const baseKey = __DEV__ ? key_dev: key_prod;

api.interceptors.request.use(function (config) {
    // Add API Key
    config.params = {
        // default key
        key: __DEV__ ? key_dev: key_prod,
        // other passed param
        ...config.params,
    };
    return config;
  });

export const apiSecure = axios.create({
    baseURL: __DEV__ ? dev_url: api_url,
    headers: {
        "Content-Type": "application/json"
    }
});

apiSecure.defaults.withCredentials = true

apiSecure.interceptors.request.use(function (config) {
  const token = localStorage.getItem('token');
  //let otherToken = document.cookie
  config.headers.Authorization =  token ? `Bearer ${token}` : '';
  config.headers['X-Endpoint-API-UserInfo'] =  token ? `Bearer ${token}` : ''; //For ESPv2 gateway JWT processing
  // Add API Key
  config.params = {
      // default key
      key: __DEV__ ? key_dev: key_prod,
      // other passed param
      ...config.params,
  };
  return config;
});